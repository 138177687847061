<template>
  <div>
    <div desktop="12" tablet="8">
      <dl>
        <dt>Login successful</dt>
        <dt>Your browser should be redirected soon</dt>
      </dl>
    </div>
  </div>
</template>

<script>

import {
  GetModuleNavigationBar,
  GetNewMenuNavigationBar,
  GetNeButtonList
} from "../api/public";

import router from "@/router";
import { resetRouter, filterAsyncRouter } from "@/router/index";

export default {
  name: "logincallback-view",
  data () {
    return {};
  },
  async created () {
    try {
     
      let _this = this;
      //  console.log(user)
      _this.$store.commit("saveToken", user.access_token);

      var curTime = new Date();
      var expiredate = new Date(
        curTime.setSeconds(curTime.getSeconds() + user.expires_in)
      );
      _this.$store.commit("saveTokenExpire", expiredate);

      window.localStorage.refreshtime = expiredate;
      window.localStorage.expires_in = user.expires_in;

      // _this.$notify({
      //   type: "success",
      //   message: `成功获取令牌，项目初始化中...`,
      //   duration: 3000
      // });

      user.uRealName = user.profile.name;
      user.uLoginName = user.profile.preferred_username;
      user.sub = user.profile.sub;
      window.localStorage.user = JSON.stringify(user);
      if (user.sub.length > 0) {

        _this.GetNavigationBar(user.sub);
      }

    } catch (e) {
      this.$root.$emit("show-snackbar", { message: e });
    }
  },
  methods: {
    // 获取路由树
    GetNavigationBar (auid) {
      var _this = this;
        var loginParams = { uid: auid, appId:global.appId };

      GetModuleNavigationBar(loginParams).then(data => {
     
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error"
          });
         this.$router.push('/refuse')
        } else {
          // _this.closeAlert()

          // _this.$message({
          //   message: "后台初始化成功",
          //   type: "success"
          // });
          let userinfo = JSON.parse(
            window.localStorage.user ? window.localStorage.user : null
          );
    

          window.localStorage.menu = JSON.stringify(data.response.children);

          let getRouter = data.response.children; //后台拿到路由
           getRouter = filterAsyncRouter(getRouter); //过滤路由
            router.$addRoutes(getRouter); //动态添加路由
          
          
            if(getRouter[0]['type']==0){//单页面引用
              this.$router.replace(
                 getRouter[0]['path']
                ).catch(data => {  });
            }else if(getRouter[0]['type']==1){//带导航应用
                 
                this.getNeButtonList(auid,  getRouter[0]['id'])
            }else if(getRouter[0]['type']==2){
             this.$router.push({ name: 'userTemplate', query: { reportUrl:  getRouter[0]['path'], reportType: 0 ,t: Date.now()}})
            }
            
        }
      });
    },
     //获取按钮
      getNeButtonList(uid,pid) {
         var _this = this;
      var loginParams = { uid: uid,mid:pid};

         GetNeButtonList(loginParams).then(data => {
           console.log(data)
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error"
          });
         this.$router.push('/refuse')
        } else {
       
            window.localStorage.buttList = JSON.stringify(data.response);
          this.GetNavigationBarByChilder(uid,pid)
        }
      });
      },
       GetNavigationBarByChilder (uid,pid) {
      var _this = this;
      var loginParams = { uid: uid,mid:pid};

      GetNewMenuNavigationBar(loginParams).then(data => {
        console.log(data)
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error"
          });
         this.$router.push('/refuse')
        } else {
       

          let getRouter = data.response.children; //后台拿到路由
          
          window.localStorage.router = JSON.stringify(data.response.children);
          if(getRouter.length > 0){
             this.isCollapsdMenu = true
              this.routes = getRouter;
         
              getRouter = filterAsyncRouter(getRouter); //过滤路由
              router.$addRoutes(getRouter); //动态添加路由
            
            if(getRouter[0]['children'] !=null && getRouter[0]['children'].length >0){
              let cPath = this.hasChilden(getRouter[0]['children'][0])
           
             _this.$router.replace(
                 cPath
                   ).catch(data => {  });
              }else{
                  _this.$router.replace(
                      getRouter[0]['path']
                   ).catch(data => {  });
              }
         
          }
          
        }
      });
    },
     hasChilden(res){
      console.log(res)
          if(res['children'] !=null && res['children'].length >0){
               this.hasChilden(res['children'][0])
          }else{
              console.log(res)
            return res['path'];
          }
    },
  }
};
</script>

<style>
</style>
